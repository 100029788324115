
.item_learning_paths {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: 30px 0;
    margin: 2rem 0;
}

.lp_card_container {
    flex: 1 1 300px;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    a {
        display: flex;
        width: 100%;
    }
    .card {
        flex: 1 1 100%;
        .card-body-container {
            height: 100%;
            .card-body {
                height: 100%;
                min-height: 270px;
                .card-title {
                    font-family: inherit;
                    font-weight: 500;
                    line-height: 1.2;
                    color: inherit;
                }
                .card-text {
                    height: 75%;
                    box-sizing: border-box;
                    overflow: hidden;
                }
            }
        }
    }
    .age_group_label {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        width: 55px;
        height: 55px;
        display: grid;
        place-content: center;
        place-items: center;
        background-color: white;
        border: solid #e87b66 6px;
        color: #e87b66;
        font-weight: bold;
        font-size: 0.7em;
        border-radius: 0.25rem;
      }
      
      .age_range_text {
        padding-bottom: 0.25rem;
      }
      
      .age_range_icon {
        padding: 0.25rem 0.5rem 0 0.5rem;
        color: #e87b66;
        fill: #e87b66;
      }
}



@media (min-width: 1200px){
    .item_learning_paths {
        .lp_card_container {
            max-width: 25%;
        }
    }
}

@media (min-width: 768px) and (max-width: 1200px){
    .item_learning_paths {
        .lp_card_container {
            max-width: 33%;
        }
    }
}